import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react";
import { AiFillDelete, AiOutlineCloudUpload } from "react-icons/ai";
import { FileDrop } from "react-file-drop";
import ApiService from '../ApiService';

function UploadQuestionViaJson() {
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);

  const handleFileDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append(`questionsFiles`, ...files);

      try {
        const response = await ApiService.uploadQuestionsViaJson(formData);
        console.log("Upload response:", response);
        // Handle success response
      } catch (error) {
        console.error("Upload error:", error);
        // Handle error response
      }
    }
  };

  const triggerFileInput = () => {
    inputRef.current.click();
  };

  return (
    <Box m={["none", "8", "10rem"]}>
      <Heading size="md" mb={4}>
        Upload Files
      </Heading>
      <FileDrop onDrop={handleFileDrop}>
        <Box
          borderWidth="1px"
          borderRadius="md"
          padding={4}
          textAlign="center"
          cursor="pointer"
        >
          <AiOutlineCloudUpload boxSize={6} color="blue.500" mb={2} />
          <Text>Drag and drop files here</Text>
          <Text fontSize="xs" color="gray.500">
            or
          </Text>
          <Button onClick={triggerFileInput} colorScheme="blue">
            Browse
          </Button>
          <Text fontSize="xs" color="gray.500" mt={2}>
            File supported: .json
          </Text>
        </Box>
      </FileDrop>

      <Input
        type="file"
        hidden
        ref={inputRef}
        onChange={handleFileSelect}
        accept=".json"
        multiple
      />

      <Box mt={4}>
        <Heading size="sm" mb={2}>
          Uploaded Files
        </Heading>
        {files.map((file, index) => (
          <HStack key={index} alignItems="center" mb={2}>
            <Flex alignItems="center">
              <Text ml={2}>{file.name}</Text>
            </Flex>
            <IconButton
              icon={<AiFillDelete />}
              onClick={() => handleRemoveFile(index)}
            />
          </HStack>
        ))}
        {files.length > 0 && (
          <Button onClick={handleUpload} colorScheme="green" mt={4}>
            Upload
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default UploadQuestionViaJson;