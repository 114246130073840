import {PropsWithChildren, useEffect} from 'react';

import {useNavigate } from 'react-router-dom';

type ProtectedRouteProps = PropsWithChildren

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const navigate = useNavigate();
  var token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
        navigate("/");
      }
  }, [navigate,token])
  return children;
}