import {
  Button,
  Card,
  CardBody,
  Image,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function CardView({ image, label, path }) {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <Card
      w={"40vh"}
      h={"45vh"}
      mt={"8"}
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
      style={{
        transition: "transform 0.3s ease",
        transform: isHovered ? "scale(1.05)" : "scale(1)",
      }}
    >
      <CardBody>
        <Stack spacing="3" alignItems={"center"}>
          <Image src={image} h={"30vh"} w={"full"} borderRadius={"10px"} />
          <Button
            type="button"
            variant="solid"
            colorScheme="blue"
            w={"20vh"}
            onClick={handleNavigate}
          >
            {label}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
}

export default CardView;
