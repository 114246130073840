import {
  Button,
  Card,
  CardBody,
  Container,
  Stack,
  Heading,
  Image,
  Input,
  VStack,
  Center,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ApiService from "../ApiService";
import ErrorComponent from "./ErrorComponent";
import { useNavigate } from "react-router-dom";
import SvgBg from "../assets/login_bg.svg";
import ColorModeSwitcher from "../ColorModeSwitcher";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const status = await ApiService.login(email, password);
      if (status) {
        console.log(status);
        // navigate to Dashboard
        navigate("/dashboard");
      } else {
        console.log("Login failed: ", status);
      }
    } catch (error) {
      console.error("Login failed:", error);
      // setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <Container maxW={["container.xl"]} h={"100vh"} p={"4"}>
      <ColorModeSwitcher/>
      <Card h={"90vh"} mt={["2", "4"]} alignItems={["center"]}>
        <CardBody>
          <Center p={"8"}>
            <Heading>Admin Panel</Heading>
          </Center>
          <Stack
            justifyContent={"space-evenly"}
            direction={["column", "column", "row"]}
          >
            <Image
              width={["22vh", "35vh", "55vh"]}
              fit={"contain"}
              src={SvgBg}
              m={"auto"}
            />
            <form>
              <VStack alignItems={"stretch"} spacing={"8"} m={"auto"} my={"16"}>
                <Heading>Welcome Back!</Heading>

                <Input
                  placeholder="Email"
                  required
                  type="email"
                  focusBorderColor="purple.600"
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
                <Input
                  placeholder="Password"
                  required
                  type="password"
                  focusBorderColor="purple.600"
                  onChange={(e) => setPassword(e.target.value)}
                ></Input>

                <Button
                  isLoading={loading}
                  loadingText="Logging in..."
                  colorScheme="purple"
                  type="button"
                  onClick={handleLogin}
                >
                  {" "}
                  Login{" "}
                </Button>
                {/* <Text textAlign={"right"}>
                  New User? {""}
                  <Button variant={"link"} colorScheme="purple">
                    <Link to={"/register"}>Register</Link>
                  </Button>
                </Text> */}
              </VStack>
            </form>
          </Stack>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Login;
