import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import CardView from "./CardView";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { MdEmail, MdPhone, MdLocationCity, MdSchool } from "react-icons/md";
import ColorModeSwitcher from "../ColorModeSwitcher";

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserData(decodedToken);
    }
  }, []);

  const handleLogOut = (event) => {
    event.preventDefault();
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"end"} pr={"10"} pt={"4"}>
        <Image
          borderRadius="full"
          boxSize="85px"
          src="https://bit.ly/dan-abramov"
          alt="Profile"
          onClick={onOpen}
        />
      </Box>
      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Image
              borderRadius="full"
              boxSize="85px"
              src="https://bit.ly/dan-abramov"
              alt="Profile"
            />
            <ColorModeSwitcher />
          </DrawerHeader>
          <DrawerBody>
            <Text fontSize={"26"} fontWeight={"bold"}>
              {userData.name || "Name not available"}
            </Text>
            <HStack pt={"4"}>
              <MdEmail />
              <Text fontSize={"13px"} flexDir={"column"}>
                {userData.email || "Email not available"}
              </Text>
            </HStack>
            <HStack
              pos={"absolute"}
              left={"0"}
              bottom={"10"}
              w={"full"}
              justifyContent={"space-evenly"}
            >
              <Button onClick={handleLogOut} colorScheme="purple">
                Log Out
              </Button>
              <Button onClick={onClose} colorScheme="purple" variant={"outline"}>
                Edit Profile
              </Button>
            </HStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box
        m={["8", "20"]}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-evenly"}
      >
        <CardView
          image={"https://img.freepik.com/free-vector/purple-background-with-quiz-word-colorful-people_52683-126.jpg?w=740&t=st=1708947960~exp=1708948560~hmac=31c8d7a0e55daf158fcfbd2660406a833f03f52c98ce0ca012de27fc42947b02"}
          label={"Create Quiz"}
          path={"/question"}
        />
        <CardView
          image={"https://img.freepik.com/free-vector/multiple-choice-quiz-game-template-competition-event-show_1017-45315.jpg?t=st=1709206590~exp=1709210190~hmac=50dbdfc5b02b24689dba7db4d267a65308162fca75d10c8a46670d7afd6c5dbb&w=826"}
          label={"All Questions"}
          path={"/all-question"}
        />
        <CardView
          image={"https://img.freepik.com/free-psd/furniture-facebook-cover-page-template_237398-164.jpg?t=st=1708948207~exp=1708951807~hmac=3720f4f0ede98db6eb91d148dbcce640875c8265708c4d291f42dca902aad7b7&w=1380"}
          label={"Create Banner"}
          path={"/banner"}
        />
        <CardView
          image={"https://img.freepik.com/free-psd/holi-festival-celebration-youtube-banner_23-2150111023.jpg?t=st=1708948239~exp=1708951839~hmac=29781cf26efab7362a41c5a6bc49177c07c28ce51b7818b7f3bc3d0be09fa1bf&w=1380"}
          label={"All Banners"}
          path={"/all-banners"}
        />
        <CardView
          image={"https://img.freepik.com/free-vector/hand-drawn-different-people-icons-pack_23-2149086450.jpg?t=st=1708948294~exp=1708951894~hmac=22a07854745eb8569f00949c7eeaeea95af9d6012fde5c9a2b1368ca40b59f19&w=996"}
          label={"All Players"}
          path={"/players"}
        />
      </Box>
    </>
  );
}

export default Home;
