import {
  Button,
  Card,
  CardBody,
  Center,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ErrorComponent from "./ErrorComponent";
import ApiService from "../ApiService";
import { Steps, StepsItem } from "@saas-ui/react";

const steps = [
  { title: "First" },
  { title: "Second" },
  { title: "Third" },
  { title: "Forth" },
  { title: "Fifth" },
];

const gameLevels = [
  'easy1', 'easy2', 'easy3', 'mid1', 'mid2', 'mid3', 'hard1', 'hard2', 'hard3'
];

function AddQuestion() {
 
  return (
    <Card m={["none", "8", "4rem"]}>
      <CardBody>
        <FormFieldWidget />
      </CardBody>
    </Card>
  );
}
function FormFieldWidget() {
  const [question, setQuestion] = useState("");
  const [topic, setTopic] = useState("");
  const [listTopic, setListTopic] = useState([]);
  const [ListSkills, setListSkills] = useState([]);
  const [level, setLevel] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [iconData, setIconData] = useState([false, false, false, false]);
  const [score, setScore] = useState("");
  const [time, setTime] = useState("");
  const [error, setError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  const toast = useToast();

  // reset data
  const _resetData = () => {
    if(nextPage >= 5)
    {
      window.location.reload();
    }
    setQuestion("");
    setOptions(["", "", "", ""]);
    setIconData([false, false, false, false]);
    setError(false);
    setIsLoader(false);
  };
  //

  const handlePageChange = () => {
    setNextPage(nextPage + 1);
    _resetData();
  };

  const handleChangeIcon = (event, index) => {
    const data = iconData.map((value, i) => i === index);
    setIconData(data);
  };
  const handleScore = (event) => {
    setScore(event.target.value);
  };
  const handleTime = (event) => {
    setTime(event.target.value);
  };
  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };
  const handleTopic = async (event) => {
    // setTopic(event.target.value);
    var data = await ApiService.getLanguageByTopic(event.target.value);
    if (data.success) {
      setListSkills(data.data);
    }
  };
  const handleLevel = (event) => {
    setLevel(event.target.value);
  };
  const handleOptions = (value, index) => {
    const data = [...options];
    data[index] = value;
    setOptions(data);
  };

  // api call here

  const getAllTopics = async () => {
    try {
      const data = await ApiService.getAllTopics();
      if (data.success) {
        console.log(data.data);
        setListTopic(data.data);
      } else {
        console.log("Get AllTopics failed: ", data.success);
      }
    } catch (error) {
      console.error("Get AllTopics failed: ", error);
      setError(true);
    }
  };

  const createQuestion = async (e) => {
    e.preventDefault();
    try {
      if (
        !(iconData.some((icon) => icon === true))
      ) {

        toast({
          title: "Fields are required",
          description: "Please select correct answer field",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      if (
        !question ||
        !topic ||
        !ListSkills ||
        !level ||
        options.some((option) => !option)
      ) {
        console.log(question, topic, ListSkills, level, options);

        toast({
          title: "Fields are required",
          description: "All Fields are required. Please enter all fields",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      setIsLoader(true);
      const data = await ApiService.addQuestion({
        question: question,
        level: level,
        topic: topic,
        skill: [...ListSkills],
        options: [
          {
            option: options[0],
            isCorrect: iconData[0],
          },
          {
            option: options[1],
            isCorrect: iconData[1],
          },
          {
            option: options[2],
            isCorrect: iconData[2],
          },
          {
            option: options[3],
            isCorrect: iconData[3],
          },
        ],
        points: score,
        timer: time,
      });
      if (data.success) {
        console.log(data.data);
        handlePageChange();
        toast({
          title: "Success",
          description: "Question Submitted Successfully!", 
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log("Create Question failed: ", data.success);
      }
    } catch (error) {
      console.error("Create Question failed: ", error);
      setError(true);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getAllTopics();
  }, []);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <>
      <Steps
        step={nextPage}
        overflowX={"auto"}
        mt={["10", "none"]}
        mb={["8", "none"]}
      >
        {steps.map((item, index) => (
          <StepsItem key={index} title={item.title} />
        ))}
      </Steps>
      <Center>
        <Text fontSize={["3xl", "5xl"]} fontWeight={"bold"}>
          Create Quize
        </Text>
      </Center>
      <VStack justifyContent={"space-evenly"}>
        
        <VStack w={"full"} pt={"8"} pb={"8"}>
          <Select required placeholder="Select Topic" onChange={handleTopic}>
            {listTopic.map((item, index) => (
              <option key={index} value={item.value}>
                {item.value}
              </option>
            ))}
          </Select>
        </VStack>

        <VStack w={"full"}  pb={"8"}>
          <Select required placeholder="Select Skills" onChange={(event)=> setTopic(event.target.value)}>
            {ListSkills.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </VStack>

        <Select required placeholder="Select Level" onChange={handleLevel}>
          {
            gameLevels.map((item, index) => (
              <option key={index} value={item}>
                {item.toUpperCase()}
              </option>
            ))
          }
        </Select>

        <VStack w={"full"} pt={"8"}>
          <FormLabel alignSelf={"start"}>Enter Question</FormLabel>
          <Input
            required
            placeholder="Question"
            onChange={handleQuestion}
            value={question}
          />
        </VStack>

        <VStack w={"full"} pt={"8"} pb={"8"}>
          <FormLabel alignSelf={"start"}>Enter Options</FormLabel>
          {["A", "B", "C", "D"].map((placeholder, index) => (
            <InputGroup key={index}>
              <Input
                required
                placeholder={`Option ${placeholder}`}
                onChange={(e) => handleOptions(e.target.value, index)}
                value={options[index]}
              />
              <InputRightAddon>
                <Icon
                  as={iconData[index] ? AiFillCheckCircle : AiFillCloseCircle}
                  color={iconData[index] ? "green" : "red"}
                  boxSize={6}
                  onClick={(e) => handleChangeIcon(e, index)}
                />
              </InputRightAddon>
            </InputGroup>
          ))}
        </VStack>

        <VStack w={"full"} pb={"8"}>
          <Select required placeholder="Select Score" onChange={handleScore}>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </Select>
        </VStack>

        <Select required placeholder="Select Time" onChange={handleTime}>
          <option value="15">15 sec</option>
          <option value="20">20 sec</option>
          <option value="30">30 sec</option>
        </Select>

        <Center p={"8"}>
          <Button
            isLoading={isLoader}
            loadingText="Submitting"
            type="button"
            w={["20vh", "20vh", "50vh"]}
            colorScheme="blue"
            onClick={createQuestion}
          >
            Submit
          </Button>
        </Center>
      </VStack>
    </>
  );
}

export default AddQuestion;