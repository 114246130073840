import {
  Box,
  Card,
  CardBody,
  Container,
  HStack,
  Image,
  Progress,
  Text,
  Button,
  VStack,
  useDisclosure,
  useToast,
  ModalFooter,
  Stack,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ErrorComponent from "./ErrorComponent";
import ApiService from "../ApiService";
import { FormDialog, FormLayout } from "@saas-ui/react";
import Loader from "./Loader";
import {
  MdEmail,
  MdPhone,
  MdLocationCity,
  MdSchool,
  MdCircleNotifications,
  MdDateRange,
  MdFileDownload,
} from "react-icons/md";

import { AiFillLinkedin } from "react-icons/ai";

import NoImage from "../assets/no-image.png";
import SearchChipSuggestion from "./SearchChipSuggestion";
import SearchInputSuggestion from "./SearchInputSuggestion";

function AllPlayers() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [collegeData, setCollegeData] = useState([]);
  const [topicData, setTopicData] = useState([]);

  const handleSelectChange = (selectedValue) => {
    searchPlayers(selectedValue);
  };

  const searchPlayers = (query) => {
    try {
      if (!query || !userData) return;

      const filteredData = userData.filter((item) => {
        const searchText = query.toString().toLowerCase();
        const topic = item.skill.map((skill) => skill.subject);
        return (
          item.name?.toString().toLowerCase().includes(searchText) ||
          "" ||
          item.email?.toString().toLowerCase().includes(searchText) ||
          "" ||
          item.city?.toString().toLowerCase().includes(searchText) ||
          "" ||
          item.college?.toString().toLowerCase().includes(searchText) ||
          "" ||
          topic?.toString().toLowerCase().includes(searchText) ||
          ""
        );
      });
      setUserData(filteredData);
    } catch (error) {
      console.error(error); // Handle errors appropriately
    }
  };

  const setFilterData = (playerData) => {
    var cityVal = playerData.map((city) => city.city);
    var collegeVal = playerData.map((college) => college.college);
    var topicVal = playerData.map((topic) =>
      topic.skill.map((skill) => skill.subject)
    );
    // removing duplicate
    cityVal = [...new Set(cityVal)];
    collegeVal = [...new Set(collegeVal)];
    topicVal = [...new Set(topicVal.flat())];
    //
    setCityData(cityVal.filter((city) => city));
    setCollegeData(collegeVal.filter((college) => college));
    setTopicData(topicVal.filter(String));
  };

  // api call here
  const getPlayers = async () => {
    try {
      setLoading(true);
      const data = await ApiService.getAllPlayers();
      if (data.success) {
        console.log(data.data);
        setUserData(data.data);
        setFilterData(data.data);
      } else {
        console.log("User Data failed: ", data.success);
      }
    } catch (error) {
      console.error("User Data failed:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlayers();
    //eslint-disable-next-line
  }, []);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card m={["2", "16"]} mt={["16", "none"]}>
          <CardBody>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                colorScheme="purple"
                onClick={(e) => {
                  e.preventDefault();
                  getPlayers();
                }}
              >
                Reset
              </Button>
            </Box>

            <Stack p={"4"} direction={["column", "column", "row"]}>
              <SearchInputSuggestion
                suggestions={cityData}
                onSelect={handleSelectChange}
                placeholder={"Select City"}
              />
              <SearchInputSuggestion
                suggestions={collegeData}
                onSelect={handleSelectChange}
                placeholder={"Select College"}
              />
              <SearchChipSuggestion
                suggestions={topicData}
                onSelect={handleSelectChange}
                placeholder={"Select Topics"}
              />
            </Stack>

            {userData.map((data, index) => (
              <Box key={index} p={"4"}>
                <ProfileComponent data={data} />
              </Box>
            ))}
          </CardBody>
        </Card>
      )}
    </>
  );
}

function ProfileComponent(data) {
  const [btnLoader, setBtnLoader] = useState(false);
  const disclosure = useDisclosure();
  const toast = useToast();

  const onSubmit = async (data) => {
    console.log(data);
    sendFCMNotification(data);
  };

  const sendFCMNotification = async (data) => {
    try {
      setBtnLoader(true);
      if (data.Token) {
        const msg = await ApiService.sendNotification(data);
        console.log(msg);
        toast({
          title: "Success",
          description: msg,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        disclosure.onClose();
      }
    } catch (error) {
      console.error("User Data failed:", error);
      toast({
        title: "Error",
        description: error.response.data.msg,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      disclosure.onClose();
    } finally {
      setBtnLoader(false);
    }
  };

  const footer = (
    <ModalFooter>
      <Button
        isLoading={btnLoader}
        loadingText="Sending ..."
        colorScheme="purple"
        type="submit"
        onClick={onSubmit}
      >
        Send
      </Button>
    </ModalFooter>
  );

  data = data.data;
  return (
    <HStack
      w={"full"}
      border={"solid 2px #5C6471"}
      p={"8"}
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={["space-evenly", "start"]}
    >
      <Image
        // borderRadius="full"
        boxSize="150px"
        w={"100px"}
        h={"150px"}
        src={data.image}
        fallbackSrc={NoImage}
        border={"solid 2px pink"}
      />

      <VStack
        p={"10"}
        display={"flex"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        {data.name && <Text>{data.name}</Text>}

        {data.email && (
          <HStack>
            <MdEmail />
            <Text fontSize={"13px"} flexDir={"column"}>
              {data.email}
            </Text>
          </HStack>
        )}

        {data.city && (
          <HStack>
            <MdLocationCity />
            <Text fontSize={"13px"} flexDir={"column"}>
              {data.city}
            </Text>
          </HStack>
        )}

        {data.phone && (
          <HStack>
            <MdPhone />
            <Text fontSize={"13px"} flexDir={"column"}>
              {data.phone}
            </Text>
          </HStack>
        )}

        {data.college && (
          <HStack>
            <MdSchool />
            <Text fontSize={"13px"} flexDir={"column"}>
              {data.college}
            </Text>
          </HStack>
        )}

        {data.graduationDate && (
          <HStack>
            <MdDateRange />
            <Text fontSize={"13px"} flexDir={"column"}>
              {data.graduationDate}
            </Text>
          </HStack>
        )}

        {data.linkedinProfile && (
          <HStack>
            <AiFillLinkedin />
            <Link fontSize={"13px"} href={data.linkedinProfile} isExternal>
              Linkedin
            </Link>
          </HStack>
        )}

        {data.resume && (
          <HStack>
            <MdFileDownload />
            <Link fontSize={"13px"} href={data.resume} isExternal>
              Resume
            </Link>
          </HStack>
        )}
      </VStack>

      <VStack display={"flex"} justifyContent={"start"} alignItems={"start"}>
        <Text color={"pink"} fontWeight={"bold"}>
          Skill Proficiency
        </Text>
        {data.skill.map((value, index) => {
          return (
            <>
              <Text fontWeight={"bold"}>{value.subject}</Text>
              <Container m={"none"} w={"full"}>
                <Progress
                  value={100 - value.percentage}
                  size="md"
                  colorScheme="green"
                  borderRadius={"10"}
                  ms={"-4"}
                />
              </Container>
              <Text fontSize={"13px"} fontWeight={"bold"}>
                Top {value.percentage}%
              </Text>
            </>
          );
        })}
      </VStack>
      {data.fcmToken && data.fcmToken !== "firebase fcm notification token" ? (
        <>
          <Button
            m={"auto"}
            h={"66"}
            w={"66"}
            onClick={() => disclosure.onOpen()}
          >
            <MdCircleNotifications size={"44"} color="#a2a2d0" />
          </Button>

          {/* form dialog */}

          <FormDialog
            title="Send Notification"
            defaultValues={{ title: "", description: "" }}
            {...disclosure}
            onSubmit={onSubmit}
            footer={footer}
          >
            {({ Field }) => (
              <FormLayout>
                <Field
                  name="Token"
                  label="Token"
                  type="text"
                  value={data.fcmToken}
                  autoFocus
                />
                <Field
                  name="title"
                  label="Title"
                  type="text"
                  rules={{ required: "Title is required" }}
                  autoFocus
                />
                <Field
                  name="description"
                  type="textarea"
                  label="Description"
                  rules={{ required: "Description is required" }}
                />
              </FormLayout>
            )}
          </FormDialog>
        </>
      ) : (
        <Container></Container>
      )}
    </HStack>
  );
}

export default AllPlayers;
