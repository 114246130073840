import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ErrorComponent from "./ErrorComponent";
import Loader from "./Loader";
import ApiService from "../ApiService";
import { BiLink } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function AllBanner() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoadingIndex, setDeleteLoadingIndex] = useState(null);
  const [banners, setbanners] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (path, argu) => {
    navigate(path, { state: { argu } });
  };

  const fetchAllBanner = async () => {
    try {
      setLoading(true);
      const data = await ApiService.getBanners();
      if (data.success) {
        console.log(data.data);
        setbanners(data.data);
      } else {
        console.log("Get All Banners failed: ", data.success);
      }
    } catch (error) {
      console.error("Get All Banners failed:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const deleteBanner = async (e, bannerID, index) => {
    e.preventDefault();
    try {
      setDeleteLoadingIndex(index);
      const data = await ApiService.deleteBanner(bannerID);
      if (data.success) {
        console.log(data.data);
        await fetchAllBanner();
      } else {
        console.log("Delete Banner failed: ", data.success);
      }
    } catch (error) {
      console.error("Delete Banner failed:", error);
      setError(true);
    } finally {
      setDeleteLoadingIndex(null);
    }
  };

  useEffect(() => {
    fetchAllBanner();
  }, []);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <Box m={["one", "8", "26"]} p={"8"}>
      <Center p={"8"}>
        <Heading>All Banners</Heading>
      </Center>
      {loading ? (
        <Loader />
      ) : (
        banners.map((banner, index) => (
          <Card
            key={banner._id}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            m={"8"}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: ["400px", "200px", "400px"] }}
              src={banner.image}
              alt="Banner"
            />
            <Stack>
              <CardBody>
                <Text py="2">{banner.description}</Text>
              </CardBody>
              <CardFooter>
                <Button
                fontSize={['10px','medium']}
                  variant="solid"
                  colorScheme="blue"
                  mr={["2","8"]}
                  onClick={(e) =>
                    handleNavigate("/banner", {
                      bannerID: banner._id,
                      image: banner.image,
                      url: banner.url,
                      description: banner.description,
                    })
                  }
                >
                  Update
                </Button>
                <Button
                fontSize={['10px','medium']}
                  isLoading={deleteLoadingIndex === index}
                  loadingText="Deleting..."
                  variant="solid"
                  colorScheme="red"
                  onClick={(e) => deleteBanner(e, banner._id, index)}
                  mr={["none","8"]}
                >
                  Delete
                </Button>

                <a href={banner.url} target="blank">
                  <BiLink size={40} color="blue" />
                </a>
              </CardFooter>
            </Stack>
          </Card>
        ))
      )}
    </Box>
  );
}

export default AllBanner;
