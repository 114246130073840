import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Home from "./components/Home";
import CreateBanner from "./components/CreateBanner";
import AllBanner from "./components/AllBanner";
import Header from "./components/Header";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AllPlayers from "./components/AllPlayers";
import Questions from "./components/Questions";
import AddQuestion from "./components/AddQuestion";
import UploadQuestionViaJson from "./components/UploadQuestionViaJson";
import AllQuestion from "./components/AllQuestion";
import UpdateQuestion from "./components/UpdateQuestion";
import ProtectedRoute from "./components/ProtectedRoute";
import LandingPage from "./components/LandingPage";
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<SignUp />} /> */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/banner"
          element={
            <ProtectedRoute>
              <CreateBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-banners"
          element={
            <ProtectedRoute>
              <AllBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question"
          element={
            <ProtectedRoute>
              {/* <AddQuestion /> */}
              <Questions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question-create"
          element={
            <ProtectedRoute>
              <AddQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question-json"
          element={
            <ProtectedRoute>
              <UploadQuestionViaJson />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-question"
          element={
            <ProtectedRoute>
              <AllQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/players"
          element={
            <ProtectedRoute>
              <AllPlayers />
            </ProtectedRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/update-question"
          element={
            <ProtectedRoute>
              <UpdateQuestion />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
