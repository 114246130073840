import {
  Button,
  Image,
  Center,
  Container,
  FormLabel,
  Input,
  VStack,
  Box,
  useToast,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ErrorComponent from "./ErrorComponent";
import ApiService from "../ApiService";
import { useLocation } from "react-router-dom";

function CreateBanner() {
  const [file, setFile] = useState(null);
  const [bannerID, setBannerID] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [description, setDescription] = useState();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleWebsiteURL = (event) => {
    setWebsiteUrl(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };
  const handleDelete = (event) => {
    setFile(null);
  };

  const handleUpdateBanner = async (e) => {
    e.preventDefault();
    try {
      if (!file || !websiteUrl) {
        toast({
          title: "Fields are required",
          description: "URL And Image are required. Please enter all fields",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("url", websiteUrl);
      if (description) {
        formData.append("description", description);
      }
      if (file.toString().startsWith("https" || "http")) {
        toast({
          title: "Fields are required",
          description: "Image are required. Please update current image",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      } else {
        formData.append("image", file);
      }
      const data = await ApiService.updateBanner(formData, bannerID);
      if (data.success) {
        console.log(data.data);
      } else {
        console.log("Update Banner failed: ", data.success);
      }
    } catch (error) {
      console.error("Update Banner failed:", error);
      setError(true);
    } finally {
      setLoading(false);
      window.history.replaceState({}, "");
      window.location.reload();
    }
  };

  const handleCreateBanner = async (e) => {
    e.preventDefault();
    try {
      if (!file || !websiteUrl) {
        toast({
          title: "Fields are required",
          description: "URL And Image are required. Please enter all fields",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("url", websiteUrl);
      if (description) {
        formData.append("description", description);
      }
      formData.append("image", file);
      const data = await ApiService.addBanner(formData);

      if (data.success) {
        console.log(data.data);
        toast({
          title: "Success",
          description: "Banner Created Successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log("Create Banner failed: ", data.success);
      }
    } catch (error) {
      console.error("Create Banner failed:", error);
      setError(true);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const navigateData = useLocation();

  

  var arguData = navigateData.state ? navigateData.state.argu : null;
  useEffect(() => {
    console.log(arguData);
    if (arguData != null) {
      setDescription(arguData.description);
      setWebsiteUrl(arguData.url);
      setFile(arguData.image);
      setBannerID(arguData.bannerID);
    }
  }, [arguData]);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <>
      {bannerID != null ? (
        <Text color={"red"} display={"flex"} justifyContent={"center"} ml={['4.5rem','none']} mt={['2.2rem','none']}>
          *It is required to update the banner image when updating otherwise it
          won't update
        </Text>
      ) : (
        <Container></Container>
      )}

      <Container maxW={"container.xl"} h={"100vh"} p={"16"}>
        <VStack w={"full"}>
          <FormLabel alignSelf={"start"}>
            Enter Description (optional)
          </FormLabel>
          <Input
            required
            value={description}
            placeholder="Description"
            onChange={handleDescription}
          />
        </VStack>
        <VStack w={"full"} pb={"8"}>
          <FormLabel alignSelf={"start"}>Enter Website Url</FormLabel>
          <Input
            required
            value={websiteUrl}
            placeholder="URL"
            onChange={handleWebsiteURL}
          />
        </VStack>

        {file ? (
          <Stack
            justifyContent={"space-evenly"}
            direction={["column", "column", "row"]}
            alignItems={"center"}
            border={"solid #51555E 1px"}
            mt={"8"}
            mb={"8"}
          >
            {file.toString().startsWith("https" || "http") ? (
              <Image m={"4"} height={"28vh"} src={file} />
            ) : (
              <Image m={"4"} height={"28vh"} src={URL.createObjectURL(file)} />
            )}
            <Button onClick={handleDelete}>Remove</Button>
          </Stack>
        ) : (
          <Box />
        )}

        <Input type="file" onChange={handleFile} />
        <Center>
          <Button
            isLoading={loading}
            loadingText="Uploading..."
            colorScheme="purple"
            type="button"
            p={"22"}
            m={"30"}
            onClick={bannerID != null ? handleUpdateBanner : handleCreateBanner}
          >
            Upload
          </Button>
        </Center>
      </Container>
    </>
  );
}

export default CreateBanner;
