import React, { useState, useEffect } from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  Flex,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md"; // Import MdSearch icon

const SearchChipSuggestion = ({
  suggestions,
  onSelect,
  onChipRemove,
  placeholder,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [chips, setChips] = useState([]);

  useEffect(() => {
    const filterSuggestions = () => {
      if (searchTerm) {
        const lowerCasedTerm = searchTerm.toLowerCase();
        const matches = suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(lowerCasedTerm)
        );
        setFilteredSuggestions(matches);
      } else {
        setFilteredSuggestions([]);
      }
    };

    filterSuggestions();
  }, [searchTerm, suggestions]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChipAdd = (suggestion) => {
    if (!chips.includes(suggestion)) {
      setChips([...chips, suggestion]);
      setSearchTerm(""); // Clear search term after adding chip
    }
  };

  const handleChipRemove = (chip) => {
    var filterData = chips.filter((c) => c !== chip);
    setChips(filterData);
    if (onChipRemove) {
      onChipRemove(chip); // Call optional onChipRemove function for custom actions
    }
  };

  const handleSuggestionClick = (suggestion) => {
    handleChipAdd(suggestion);
    setFilteredSuggestions([]); // Clear suggestions on selection
    onSelect(suggestion); // Call provided onSelect function
  };

  return (
    <Box position="relative" w={"full"}>
      <VStack alignItems="center">
        <Input
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          icon={<MdSearch />}
        />
        <HStack>
          {chips.map((chip) => (
            <Tag key={chip} colorScheme="teal" mr={2} mt={1}>
              <TagLabel>{chip}</TagLabel>
              <TagCloseButton onClick={() => handleChipRemove(chip)} />
            </Tag>
          ))}
        </HStack>
      </VStack>
      {filteredSuggestions.length > 0 && (
        <List boxShadow="md" borderRadius="md" mt={1}>
          {filteredSuggestions.map((suggestion) => (
            <ListItem
              key={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text>{suggestion}</Text>
              </Flex>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default SearchChipSuggestion;
