import {
  Box,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  FaTelegram,
  FaInstagram,
  FaLinkedin,
  FaGooglePlay,
} from "react-icons/fa6";

import AppLogo from "../assets/skillzy_logo.png";
function Footer() {
  return (
    <Box bgColor={"blackAlpha.900"} color={"white"} p={"16"}>
      <Stack direction={["column", "row"]}>
        <VStack
          w={"full"}
          borderRight={["none", "1px solid white"]}
          borderBottom={["2px solid white", "none"]}
          paddingBottom={["12px", "none"]}
        >
          <VStack>
            <Image src={AppLogo} h={"55"} w={"55"} />
            <Heading textTransform={"uppercase"}>Skillzy</Heading>
          </VStack>
          <Text>All rights reserved (2024).</Text>
        </VStack>
        <VStack w={"full"} justifyContent={"center"}>
          <Heading size={"md"} textTransform={"uppercase"}>
            Social Link
          </Heading>
          <HStack>
            <a
              href="https://play.google.com/store/apps/details?id=io.skillzy.android"
              target="blank"
            >
              <FaGooglePlay size={40} />
            </a>
            <a href="https://telegram.me/Skillzy_io" target="blank">
              <FaTelegram size={40} />
            </a>
            <a href="https://www.instagram.com/Skillzy.io/" target="blank">
              <FaInstagram size={40} />
            </a>
            <a
              href="https://www.linkedin.com/company/skillzy-io/about/"
              target="blank"
            >
              <FaLinkedin size={40} />
            </a>
          </HStack>
        </VStack>
      </Stack>
    </Box>
  );
}

export default Footer;
