import React, { useState, useEffect } from "react";
import { Input, Box, List, ListItem, Flex, Text } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md"; // Import MdSearch icon

const SearchInputSuggestion = ({ suggestions, onSelect, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [hideSuggestions, setHideSuggestions] = useState(false);

  useEffect(() => {
    const filterSuggestions = () => {
      if (searchTerm && hideSuggestions === false) {
        const lowerCasedTerm = searchTerm.toLowerCase();
        const matches = suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(lowerCasedTerm)
        );
        setFilteredSuggestions(matches);
      } else {
        setFilteredSuggestions([]);
      }
    };

    filterSuggestions();
  }, [hideSuggestions, searchTerm, suggestions]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSuggestionClick = (data) => {
    setFilteredSuggestions([]); // Clear suggestions on selection
    setSearchTerm(data); // Clear input field
    onSelect(data); // Call provided onSelect function
    setHideSuggestions(true);
  };

  return (
    <Box position="relative" w={"full"}>
      <Input
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        icon={<MdSearch />}
      />
      {
        <List boxShadow="md" borderRadius="md" mt={1}>
          {filteredSuggestions.length > 0
            ? filteredSuggestions.map((data) => (
                <ListItem
                  p={"2"}
                  key={data}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSuggestionClick(data);
                  }}
                  cursor={"pointer"}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>{data}</Text>
                  </Flex>
                </ListItem>
              ))
            : null}
        </List>
      }
    </Box>
  );
};

export default SearchInputSuggestion;
