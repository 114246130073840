import axios from "axios";

const _BASE_URL = "https://skillzy.onrender.com";
// const _BASE_URL = "https://api.skillzy.io";
// const _BASE_URL = "http://localhost:3001"

const ApiService = {
  login: async (email, password) => {
    const { data } = await axios.post(`${_BASE_URL}/admin-auth/login`, {
      email,
      password,
    },
    );
    if (data.success) {
      localStorage.setItem("token", data.data);
    }
    return data.data;
  },
  signUp: async (name, email, phone, password) => {
    const { data } = await axios.post(
      `${_BASE_URL}/user-auth/signup-send-otp`,
      {
        name,
        email,
        phone,
        password,
        role: "interviewer",
        fcmToken: "firebase fcm notification token",
      }
    );
    return data;
  },
  signUpWithOTP: async (name, email, phone, password, otp) => {
    const { data } = await axios.post(`${_BASE_URL}/user-auth/signup`, {
      name,
      email,
      phone,
      password,
      otp,
      role: "interviewer",
      fcmToken: "firebase fcm notification token",
    });

    if (data.success) {
      localStorage.setItem("token", data.data.token);
    }

    return data;
  },
  getAllPlayers: async () => {
    var token = localStorage.getItem("token");
    const { data } = await axios.get(`${_BASE_URL}/api/admin/players`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  },
  searchPlayers: async () => {
    var token = localStorage.getItem("token");
    const { data } = await axios.get(`${_BASE_URL}/api/interviewees`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  },
  getAllTopics: async () => {
    var token = localStorage.getItem("token");
    const { data } = await axios.get(`${_BASE_URL}/api/admin/languages`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  },
  getLanguageByTopic: async (topic) => {
    var token = localStorage.getItem("token");
    const { data } = await axios.get(
      `${_BASE_URL}/api/admin/languages?topic=${topic}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data;
  },
  getAllQuestions: async () => {
    var token = localStorage.getItem("token");
    const { data } = await axios.get(`${_BASE_URL}/api/questions`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  },
  
  addQuestion: async (questionData) => {
    var token = localStorage.getItem("token");
    const { data } = await axios.post(
      `${_BASE_URL}/api/question`,
      questionData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  },
  deleteQuestion: async (questionId) => {
    // Delete question via API
  },
  updateQuestion: async (questionId, updatedData) => {
    var token = localStorage.getItem("token");
    const { data } = await axios.patch(
      `${_BASE_URL}/api/question/${questionId}`,
      updatedData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  },
  getBanners: async () => {
    var token = localStorage.getItem("token");

    const { data } = await axios.get(`${_BASE_URL}/api/banners`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  },
  addBanner: async (bannerData) => {
    var token = localStorage.getItem("token");
    const { data } = await axios.post(`${_BASE_URL}/api/banner`, bannerData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  },
  deleteBanner: async (bannerId) => {
    var token = localStorage.getItem("token");
    const { data } = await axios.delete(
      `${_BASE_URL}/api/banners/${bannerId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  },
  updateBanner: async (bannerData, bannerId) => {
    console.log(bannerData);
    console.log("Banner ID: ", bannerId);

    var token = localStorage.getItem("token");
    const { data } = await axios.patch(
      `${_BASE_URL}/api/banners/${bannerId}`,
      bannerData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(data.data);

    return data;
  },
  // sending fcm notifications
  sendNotification: async (fcmData) => {
    console.log("Inside Api Call: ", fcmData);
    const body = {
      token: fcmData.Token,
      title: fcmData.title,
      subject: fcmData.description,
    };
    const { data } = await axios.post(
      "https://admin-firebase-sable.vercel.app/api/send-notification",
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(data);
    return data.msg;
  },
  uploadQuestionsViaJson: async (formData) => {
    const token = localStorage.getItem("token");
    try {
       const { data } = await axios.post(
        `${_BASE_URL}/api/questions`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      return error.response;
    }
  },
};

export default ApiService;