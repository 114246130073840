import CardView from "./CardView";
import {
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function Questions() {
  return (
    <>
      {/* profile Drawer here */}
      <Box
        m={["8", "20"]}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-evenly"}
      >
        <CardView
          image={
            "https://img.freepik.com/free-vector/purple-background-with-quiz-word-colorful-people_52683-126.jpg?w=740&t=st=1708947960~exp=1708948560~hmac=31c8d7a0e55daf158fcfbd2660406a833f03f52c98ce0ca012de27fc42947b02"
          }
          label={"Create Quiz"}
          path={"/question-create"}
        />
        <CardView
          image={
            "https://img.freepik.com/free-vector/multiple-choice-quiz-game-template-competition-event-show_1017-45315.jpg?t=st=1709206590~exp=1709210190~hmac=50dbdfc5b02b24689dba7db4d267a65308162fca75d10c8a46670d7afd6c5dbb&w=826"
          }
          label={"Quiz Via Json"}
          path={"/question-json"}
        />

      </Box>
    </>
  );
}

export default Questions;
