import {
  Box,
  Text,
  Badge,
  Stack,
  Flex,
  Card,
  CardBody,
  Center,
  Button,
  AccordionIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import ApiService from "../ApiService";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "./ErrorComponent";
import { SearchInput } from "@saas-ui/react";

function AllQuestion() {
  const [questionData, setQuestionData] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [error, setError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const groupByTopic = (questions) => {
    return questions.reduce((acc, question) => {
      const { topic } = question;
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push(question);
      return acc;
    }, {});
  };

  const searchQuestion = (event) => {
    event.preventDefault();
    const query = event.target.value.toLowerCase();
    const filtered = Object.keys(questionData).reduce((acc, topic) => {
      const filteredQuestions = questionData[topic].filter((item) => 
        item.question.toLowerCase().includes(query)
      );
      if (filteredQuestions.length > 0) {
        acc[topic] = filteredQuestions;
      }
      return acc;
    }, {});
    setFilteredData(filtered);
  };

  const fetchQuestions = async () => {
    try {
      setIsLoader(true);
      const data = await ApiService.getAllQuestions();
      if (data.success) {
        const groupedData = groupByTopic(data.data);
        setQuestionData(groupedData);
        setFilteredData(groupedData);
      } else {
        console.log("Question Data failed: ", data.success);
      }
    } catch (error) {
      console.error("Question Data failed:", error);
      setError(true);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <Box m={["8", "20"]} display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"}>
      <Text fontSize={"22"} fontWeight={"bold"}>Total Question: {Object.values(filteredData).flat().length}</Text>
      <Box m={"10"} w={"full"}>
        <SearchInput onChange={searchQuestion} onReset={(e) => setFilteredData(questionData)} />
      </Box>
      {isLoader ? (
        <Loader />
      ) : (
        Object.keys(filteredData).map((topic) => (
          <Box key={topic} w={"full"} p={4}>
            <Text fontSize={"20"} fontWeight={"bold"} mb={4}>{`Topic: ${topic}`}</Text>
            {filteredData[topic].map((question, index) => (
              <Accordion key={index} w={'full'} pl={10} pr={10} pb={4} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {question.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <CardWidget data={question} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ))}
          </Box>
        ))
      )}
    </Box>
  );
}

const CardWidget = ({ data }) => {
  const navigate = useNavigate();

  const handleNavigate = (path, argu) => {
    navigate(path, { state: { argu } });
  };

  return (
    <Card>
      <CardBody>
        <Box borderWidth="1px" borderRadius="lg" p={"22"}>
          <Text fontSize="xl" fontWeight="bold" mb="2">{data.question}</Text>
          <Stack spacing="2">
            <Flex justifyContent="space-between">
              <Badge colorScheme="green">{data.level}</Badge>
              <Badge colorScheme="blue">{data.topic}</Badge>
            </Flex>
            <Text fontSize="md">Skills: {data.skill.join(", ")}</Text>
            <Text fontSize="md">Points: {data.points}</Text>
            <Text fontSize="md">Timer: {data.timer} seconds</Text>
            <Text fontSize="md">Created At: {new Date(data.createdAt).toLocaleString()}</Text>
            <Text fontSize="md">Updated At: {new Date(data.updatedAt).toLocaleString()}</Text>
          </Stack>
          <Center mt={"8"}>
            <Button bg={"#094a99"} color={"white"} onClick={(e) => handleNavigate("/update-question", data)}>
              Update
            </Button>
          </Center>
        </Box>
      </CardBody>
    </Card>
  );
};

export default AllQuestion;
