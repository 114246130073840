import React from 'react';
import {
  Button,
  Image,
} from '@chakra-ui/react';

import {  useNavigate } from 'react-router-dom';
import logoSrc from "../assets/skillzy_logo.png"

function Header() {
  const navigator = useNavigate();
  const handleNavigate = () => {
   navigator('/dashboard');
  }
  return (
    <>
      <Button
        zIndex={'overlay'}
        pos={'fixed'}
        top={4}
        left={4}
        colorScheme="purple"
        p={'0'}
        w={'10'}
        h={'10'}
        borderRadius={'full'}
      >
        <Image src={logoSrc} onClick={handleNavigate} />
      </Button>
     
    </>
  );
}

export default Header;
