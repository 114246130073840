import { Button,  Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
  const loginScreen = (e) => {
    e.preventDefault();
    navigate("/login");
  }
  return (
    <Stack  w={"full"} h={"100vh"} bg={"#0C111E"} direction={['column', 'row']} alignItems={"center"}>
      <VStack display={"flex"} spacing={4} ml={["4","8"]} mt={["8","none"]} >
      
        <Text align={"center"} fontSize={["1.2rem","3.2rem"]} fontWeight={"semi-bold"} color={"white"} p={"4"} fontFamily={"fantasy"} letterSpacing={"2px"}>
        AI Based Hiring
        </Text>
        <Text align={"center"} fontSize={["1.2rem","2.2rem"]} fontWeight={"semi-bold"} color={"#DF00C9"} fontFamily={"fantasy"} m={"8"} >
        Revolutionizing hiring with our AI-driven platform, streamlining the recruitment process and delivering the ideal candidate for your team in under 48 hours.
        </Text>
      
        <Button borderRadius={"3rem"} color={"#3D53DB"} bg={"white"} w={"10rem"} h={"3rem"} onClick={loginScreen}>
          Get Started
        </Button>
      </VStack>
       {/* <Image src={imgUri} w={["240px","310px"]} h={["520px","700px"]} m={"auto"} /> */}
    </Stack>
  );
}

export default LandingPage;
