import {
  Button,
  Card,
  CardBody,
  Center,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ErrorComponent from "./ErrorComponent";
import ApiService from "../ApiService";
import {  useLocation } from "react-router-dom";

function UpdateQuestion() {

  return (
    <Card m={["none", "8", "4rem"]}>
      <CardBody>
        <FormFieldWidget />
      </CardBody>
    </Card>
  );
}
function FormFieldWidget() {
  const navigateData = useLocation();
  const [questionID, setQuestionID] = useState("");
  const [question, setQuestion] = useState("");
  const [topic, setTopic] = useState("");
  const [level, setLevel] = useState("");
  const [skill, setSkill] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [iconData, setIconData] = useState([false, false, false, false]);
  const [score, setScore] = useState("");
  const [error, setError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const toast = useToast();

  const handleScore = (event) => {
    setScore(event.target.value);
  };
  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };
  const handleTopic = async (event) => {
    setTopic(event.target.value);
  };
  const handleLevel = (event) => {
    setLevel(event.target.value);
  };

  const handleSkill = (event) => {
    setSkill(event.target.value);
  };
  const handleOptions = (value, index) => {
    const data = [...options];
    data[index] = value;
    setOptions(data);
  };

  const handleChangeIcon = (event, index) => {
    const data = iconData.map((value, i) => i === index);
    setIconData(data);
  };

  // api call here

  const updateQuestion = async (e) => {
    e.preventDefault();
    try {
      if (!questionID) {
        toast({
          title: "Something went wrong",
          description: "Please try again later",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      if (!iconData.some((icon) => icon === true)) {
        toast({
          title: "Fields are required",
          description: "Please select correct answer field",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      if (
        !question ||
        !score ||
        !topic ||
        !skill ||
        !level ||
        options.some((option) => !option)
      ) {
        toast({
          title: "Fields are required",
          description: "All Fields are required. Please enter all fields",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      setIsLoader(true);
      const data = await ApiService.updateQuestion(questionID,{
        question: question,
        level: level,
        topic: topic,
        skill: [skill],
        options: [
          {
            option: options[0],
            isCorrect: iconData[0],
          },
          {
            option: options[1],
            isCorrect: iconData[1],
          },
          {
            option: options[2],
            isCorrect: iconData[2],
          },
          {
            option: options[3],
            isCorrect: iconData[3],
          },
        ],
        points: score,
      });
      if (data.success) {
        console.log(data.data);
        toast({
          title: "Success",
          description: "Question Updated Successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log("Create Question failed: ", data.success);
      }
    } catch (error) {
      console.error("Create Question failed: ", error);
      setError(true);
    } finally {
      setIsLoader(false);
    }
  };

  var arguData = navigateData.state ? navigateData.state.argu : null;

  useEffect(() => {
    if (arguData != null) {
       setQuestionID(arguData._id);
      if (Array.isArray(arguData.options)) {
        var optData = arguData.options.map((opt) => opt.option);
        var optIconData = arguData.options.map((opt) => opt.isCorrect);
        setOptions(optData);
        setIconData(optIconData);
      }
      setQuestion(arguData.question);
      setLevel(arguData.level);
      setTopic(arguData.topic);
      setSkill(arguData.skill.toString());
      setScore(arguData.points);
    }
  }, [arguData]);

  if (error) return <ErrorComponent message={"something-went-wrong"} />;

  return (
    <>
      <Center>
        <Text fontSize={["3xl", "5xl"]} fontWeight={"bold"}>
          Update Question
        </Text>
      </Center>
      <VStack w={"full"} pt={"8"}>
        <FormLabel alignSelf={"start"}>Question</FormLabel>
        <Input
          required
          placeholder="Question"
          onChange={handleQuestion}
          value={question}
        />
      </VStack>

      <VStack w={"full"} pt={"8"} pb={"8"}>
        <FormLabel alignSelf={"start"}>Enter Options</FormLabel>
        {["A", "B", "C", "D"].map((placeholder, index) => (
          <InputGroup key={index}>
            <Input
              required
              placeholder={`Option ${placeholder}`}
              onChange={(e) => handleOptions(e.target.value, index)}
              value={options[index]}
            />
            <InputRightAddon>
              <Icon
                as={iconData[index] ? AiFillCheckCircle : AiFillCloseCircle}
                color={iconData[index] ? "green" : "red"}
                boxSize={6}
                onClick={(e) => handleChangeIcon(e, index)}
              />
            </InputRightAddon>
          </InputGroup>
        ))}
      </VStack>

      <VStack w={"full"} pt={"8"}>
        <FormLabel alignSelf={"start"}>Level</FormLabel>
        <Input
          required
          placeholder="Level"
          onChange={handleLevel}
          value={level}
        />
      </VStack>
      <VStack w={"full"} pt={"8"}>
        <FormLabel alignSelf={"start"}>Topic</FormLabel>
        <Input
          required
          placeholder="Topic"
          onChange={handleTopic}
          value={topic}
        />
      </VStack>
      <VStack w={"full"} pt={"8"}>
        <FormLabel alignSelf={"start"}>Skill</FormLabel>
        <Input
          required
          placeholder="Skill"
          onChange={handleSkill}
          value={skill}
        />
      </VStack>

      <VStack w={"full"} pt={"8"}>
        <FormLabel alignSelf={"start"}>Points</FormLabel>
        <Input
          required
          placeholder="Points"
          onChange={handleScore}
          value={score}
        />
      </VStack>

      <VStack justifyContent={"space-evenly"}>
        <Center p={"8"}>
          <Button
            isLoading={isLoader}
            loadingText="Submitting"
            type="button"
            w={["20vh", "20vh", "50vh"]}
            colorScheme="blue"
            onClick={updateQuestion}
          >
            Update
          </Button>
        </Center>
      </VStack>
    </>
  );
}

export default UpdateQuestion;
